.headerB {
    box-shadow: 8px 2px 28px 2px rgba(0,0,0,0.5)
}
.header {
    display: flex;
    max-width: 1360px;
    margin: auto;
    padding: 10px 80px;

}

.header > img {
    height: 90px;
    width: 90px;
    margin-right: 100px;
}

.menu {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: auto 60px;
}

.menu>a {
    color: black;
    text-decoration: none;
}

.menu>a:hover {
    color: black;
    text-decoration: underline;
}
