.imageCover {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding: 0px 80px;
    color: white;
}

.accuelTextContainer {
    max-width: 1360px;
    margin: auto;
    padding: 40px 50px 30px;
}

.accuelTextContainer  > h1 {
    color: #21804d;
    text-align: center;
    margin-bottom: 60px;
}

.formInfoPerso {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
}

.formInfoPerso.amount {
    width: 100%;
    justify-content: unset
}
.formInfoPerso > input{
    width: 45%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    outline: 0;
    font-size: 16px;
    border: 1px solid lightgrey;
}

.formInfoPerso > select{
    width: 45%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    outline: 0;
    font-size: 16px;
    border: 1px solid lightgrey;
}

.stepButton > span > span > .Mui-active {
    color: #21804d;
}

.Hero2 {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
}

.imageHero {
    width: 300px;
    height: 300px;
    margin: auto;
}

.textHero {
    width: 60%
}

.textForm {
    width: 80%;
    margin: auto;
}

.textForm.amount {
    width: 100%;
}

.payButton {
    background: #21804d;
    color: white;
    margin: auto 10px;
    outline: 0;
    padding: 10px;
    border: 0;
    border-radius: 10px;
    font-size: 16px;
    text-decoration: none;
}

.textForm.error {
    color: red;
}
